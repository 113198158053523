// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// eslint-disable-next-line prettier/prettier
import {
  FeesState,
  IFilter,
  FillFeesListActionPayload,
  IFee,
} from './typedefs';

export const defaultFilter: IFilter = {
  limit: 5,
  offset: 0,
  search: '',
  commissionStatus: 'ALL',
};

export const defaultList = {
  isFeesFetching: true,
  isFeeChanging: null,
  count: 0,
  result: [],
  filter: defaultFilter,
  error: false,
  processingFee: null,
};

const initialState: FeesState = defaultList;

const feesSlice = createSlice({
  name: 'lists',
  initialState,
  reducers: {
    // Fees
    startFeesFetching(state) {
      state.isFeesFetching = true;
    },
    stopFeesFetching(state) {
      state.isFeesFetching = false;
    },
    startFeeChanging(state, action) {
      state.isFeeChanging = action.payload;
    },
    stopFeeChanging(state) {
      state.isFeeChanging = null;
    },
    fillFeesList(state, action: PayloadAction<FillFeesListActionPayload>) {
      state.count = action.payload.count;
      state.result = action.payload.result;
      state.filter = action.payload.filter;
      state.error = action.payload.error;
    },
    resetState(state) {
      state = defaultList;
    },
    fillProcessingFee(state, action: PayloadAction<IFee | null>) {
      state.processingFee = action.payload;
    },
    // INJECT
  },
});

export default feesSlice;
