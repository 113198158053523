import { jwtDecode } from 'jwt-decode';

type RequestParams = {
  endpoint: string;
  body?: any;
  direct?: boolean;
  headers?: any;
  unsafe?: boolean;
  query?: any;
};

interface Token {
  id: string;
  exp: number;
}

type ExecArgs = {
  endpoint: string;
  method: string;
  body: any;
  direct: boolean;
  headers?: any;
  query?: any;
};

export const api = {
  get: ({
    endpoint,
    direct = false,
    headers,
    unsafe,
    query,
  }: RequestParams) => {
    const exec = unsafe ? fetchWithoutRefresh : fetchWithRefresh;

    return exec({
      endpoint,
      method: 'GET',
      body: null,
      direct,
      headers,
      query,
    });
  },

  post: ({
    endpoint,
    body,
    direct = false,
    headers,
    unsafe,
  }: RequestParams) => {
    const exec = unsafe ? fetchWithoutRefresh : fetchWithRefresh;

    return exec({
      endpoint,
      method: 'POST',
      body,
      direct,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    });
  },

  put: ({
    endpoint,
    body,
    direct = false,
    headers,
    query,
    unsafe,
  }: RequestParams) => {
    const exec = unsafe ? fetchWithoutRefresh : fetchWithRefresh;

    return exec({
      endpoint,
      method: 'PUT',
      body,
      direct,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      query,
    });
  },

  patch: ({
    endpoint,
    body,
    direct = false,
    headers,
    query,
    unsafe,
  }: RequestParams) => {
    const exec = unsafe ? fetchWithoutRefresh : fetchWithRefresh;

    return exec({
      endpoint,
      method: 'PATCH',
      body,
      direct,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      query,
    });
  },

  delete: ({
    endpoint,
    body,
    direct = false,
    headers,
    query,
    unsafe,
  }: RequestParams) => {
    const exec = unsafe ? fetchWithoutRefresh : fetchWithRefresh;

    return exec({
      endpoint,
      method: 'DELETE',
      body,
      direct,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      query,
    });
  },
};

export function fetchWithoutRefresh({
  endpoint,
  method,
  body,
  direct = false,
  headers,
  query,
}: ExecArgs) {
  const queryString = query ? '?' + query : '';
  const url = direct
    ? endpoint
    : `${process.env.REACT_APP_API_URL}/${endpoint}${queryString}`;

  return fetch(url, {
    method,
    body,
    headers,
    credentials: 'include',
  });
}

export const fetchWithRefresh = async ({
  endpoint,
  method,
  body,
  direct = false,
  headers,
  query,
}: ExecArgs) => {
  let tokenAccess = await getValidToken();

  if (!tokenAccess) {
    return new Error('Unauthorized request');
  }
  const queryString = query ? '?' + query : '';
  const url = direct
    ? endpoint
    : `${process.env.REACT_APP_API_URL}/${endpoint}${queryString}`;

  let response = await fetch(url, {
    method,
    headers: {
      Authorization: 'Bearer ' + removeQuotes(tokenAccess!),
      ...headers,
    },
    body,
  });

  if (response.status === 401 || response.status === 403) {
    const newToken = await refreshToken();

    if (newToken.token) {
      accessToken.set(newToken.token);
      tokenAccess = newToken.token;

      response = await fetch(url, {
        method,
        headers: {
          Authorization: 'Bearer ' + removeQuotes(tokenAccess!),
          ...headers,
        },
        body,
      });
    }
  }

  if (response.status === 401 || response.status === 403) {
    await logout();
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  }

  return response;
};

export const getValidToken = async () => {
  let tokenAccess = accessToken.get();

  if (tokenAccess) {
    const { exp } = jwtDecode<Token>(tokenAccess);

    if (Date.now() >= exp * 1000) {
      try {
        const newToken = await refreshToken();
        accessToken.set(newToken.token);
        tokenAccess = newToken.token;
      } catch (error) {
        throw new Error('Private request error');
      }
    }
  }

  return tokenAccess;
};

export const refreshToken = async () => {
  return await fetch(`${process.env.REACT_APP_API_URL}/auth/refresh`, {
    method: 'GET',
    credentials: 'include',
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }

      throw new Error();
    })
    .catch((e) => {
      return { token: null };
    });
};

export const logout = async () => {
  await fetch(`${process.env.REACT_APP_API_URL}/auth/logout`, {
    method: 'GET',
    credentials: 'include',
  });
};

export const accessToken = {
  get: () => {
    return localStorage.getItem('accessToken');
  },
  set: (newToken: any) => {
    localStorage.setItem('accessToken', removeQuotes(newToken));
  },
};

export const verifyToken = (token: string) => {
  const { exp } = jwtDecode<Token>(token);

  return Date.now() < exp * 1000;
};

export const removeQuotes = (str: string) => str.replace(/^"(.*)"$/, '$1');
