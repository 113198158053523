import modalSlice from '@core/Modal/state/slice';
import authSlice from '@bus/auth/slice';
import profileSlice from '@bus/profile/slice';
import uiSlice from '@bus/ui/slice';
import feesSlice from '@bus/fees/slice';
import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// IMPORTS

export const rootReducer = combineReducers({
  modal: modalSlice.reducer,
  ui: persistReducer(
    {
      key: 'ui',
      storage,
    },
    uiSlice.reducer,
  ),
  auth: authSlice.reducer,
  profile: profileSlice.reducer,
  fees: feesSlice.reducer,
  // INJECT
});
