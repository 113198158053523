import prepareActions from '@helpers/prepareActions';
import { createAction } from '@reduxjs/toolkit';

import authSlice from './slice';

export const authActions = {
  ...authSlice.actions,
  authenticate: createAction('auth/authenticate'),
  sendEmail: createAction('auth/sendEmail', prepareActions.movePromiseToMeta),
  logIn: createAction('auth/logIn', prepareActions.movePromiseToMeta),
  logout: createAction('auth/logout'),
};
