/* eslint-disable prettier/prettier */
import layouts from '@layouts/index';
import { commonRoutes } from '@pages/common/routes';
import { book } from '@routes/book';
import { AppRoute } from '@setup/typedefs';
import { lazy } from 'react';

const LogInPage = lazy(() => import('@pages/public/LogInPage'));
const AuthenticatePage = lazy(() => import('@pages/public/AuthenticatePage'));
// IMPORTS
export const publicRoutes: AppRoute<any>[] = [
  ...commonRoutes,
  ...[
    {
      path: `${book.logIn}`,
      Component: LogInPage,
      Layout: layouts.AuthLayout,
      layoutProps: {},
    },
    {
      path: `${book.authenticate}`,
      Component: AuthenticatePage,
      Layout: layouts.AuthLayout,
      layoutProps: {},
    },
    // INJECT
  ],
];
