import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import classes from './styles.module.scss';
import SpinnerCircle from '@components/common/SpinnerCircle';

export type CommonLayoutProps = any;

const CommonLayout: React.FC<CommonLayoutProps> = () => {
  return (
    <div className={classes.root}>
      <Suspense
        fallback={
          <div className={classes.spinnerWrapper}>
            <SpinnerCircle />
          </div>
        }>
        <Outlet />
      </Suspense>
    </div>
  );
};

export default CommonLayout;
