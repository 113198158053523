import { takeEvery, all, call } from 'redux-saga/effects';
import { feesActions } from '@bus/fees/actions';

// eslint-disable-next-line prettier/prettier
import { fetchFees, changeFee } from './workers';

// IMPORTS

function* watchFetchFees() {
  yield takeEvery(feesActions.fetchFees.type, fetchFees);
}
function* watchChangeFee() {
  yield takeEvery(feesActions.changeFee.type, changeFee);
}

// WATCHERS
export function* watchFees() {
  // eslint-disable-next-line prettier/prettier
  yield all([call(watchFetchFees), call(watchChangeFee)]);
}
