// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bDYTJDJPnr1FKZ9vM5DU{display:flex;flex-direction:column;box-sizing:border-box;width:100vw;height:100vh;background:#fff}.iVHyOnrb8AJwctNrDNiE{display:flex;justify-content:center;align-items:center;box-sizing:border-box;width:100vw;height:100vh}`, "",{"version":3,"sources":["webpack://./src/layouts/CommonLayout/styles.module.scss"],"names":[],"mappings":"AACA,sBACE,YAAA,CACA,qBAAA,CACA,qBAAA,CACA,WAAA,CACA,YAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,qBAAA,CACA,WAAA,CACA,YAAA","sourcesContent":["@import \"@styles/variables\";\n.root {\n  display: flex;\n  flex-direction: column;\n  box-sizing: border-box;\n  width: 100vw;\n  height: 100vh;\n  background: #FFFFFF;\n}\n\n.spinnerWrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  box-sizing: border-box;\n  width: 100vw;\n  height: 100vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `bDYTJDJPnr1FKZ9vM5DU`,
	"spinnerWrapper": `iVHyOnrb8AJwctNrDNiE`
};
export default ___CSS_LOADER_EXPORT___;
