import React from 'react';
import { ModalTypes } from '@core/Modal/types';
import Button from '@components/common/Button';
import icon from '@assets/notification-success.svg';
import closeIcon from '@assets/close.svg';
import classes from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getProcessingFee } from '@bus/fees/selectors';
import { feesActions } from '@bus/fees/actions';
import { useSnackbar } from 'notistack';

type AlertProps = {
  continueProcess: any;
};

export const Alert: React.FC<ModalTypes.ModalComponentProps<AlertProps>> = ({
  closeFn,
  continueProcess,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const processingFee = useSelector(getProcessingFee);

  const onSave = () => {
    processingFee &&
      dispatch(
        feesActions.changeFee({
          values: processingFee,
          resolve: async () => enqueueSnackbar(`Saved`, { variant: 'success' }),
          reject: async () => enqueueSnackbar(`Error`, { variant: 'error' }),
        }),
      );
    closeFn();
    continueProcess();
  };

  const onCancel = () => {
    dispatch(feesActions.fillProcessingFee(null));
    closeFn();
    continueProcess();
  };

  return (
    <div className={classes.root}>
      <button className={classes.closeBtn} onClick={closeFn}>
        <img src={closeIcon} alt="Close" height={20} width={20} />
      </button>
      <img className={classes.icon} src={icon} alt={'Notification'} />

      <h3>Unsaved Changes</h3>
      <p>
        You have unsaved changes for your co-op fee.
        <br />
        Do you want to save them before proceeding?
      </p>
      <div className={classes.btns}>
        <Button label={'Save changes'} onClick={onSave} />
        <Button
          label={'Proceed without saving'}
          color={'secondary'}
          onClick={onCancel}
        />
      </div>
    </div>
  );
};
