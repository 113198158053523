import { createSelector } from 'reselect';

import { RootState } from '@setup/typedefs';

export const feesSelector = (state: RootState) => state.fees;

export const getIsFeesFetching = createSelector([feesSelector], (result) => {
  return result.isFeesFetching;
});

export const getIsFeeChanging = createSelector([feesSelector], (result) => {
  return result.isFeeChanging;
});

export const getProcessingFee = createSelector([feesSelector], (result) => {
  return result.processingFee;
});
