import * as effects from 'redux-saga/effects';

import { api } from '@REST/api';
import { IProfile } from '@bus/profile/typedefs';

import { profileActions } from '../../actions';
import { put } from 'redux-saga/effects';
import { authActions } from '@bus/auth/actions';

export function* fetchProfile() {
  try {
    yield effects.put(profileActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: 'users/me' },
    ]);

    if (
      response.status === 400 ||
      response.status === 401 ||
      response.status === 403
    ) {
      yield put(authActions.logout());
    }

    if (!response.ok) {
      return new Error();
    }
    const data: IProfile = yield effects.call([response, 'json']);
    yield effects.put(profileActions.fillProfile(data));
  } catch (e) {
    console.warn(e);
  } finally {
    yield effects.put(profileActions.stopFetching());
  }
}
