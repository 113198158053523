import { call, put } from 'redux-saga/effects';

import {
  refreshToken,
  accessToken,
  verifyToken,
  removeQuotes,
} from '@REST/api';

import { authActions } from '../../actions';
import * as effects from 'redux-saga/effects';
import { profileActions } from '@bus/profile/actions';

export function* authenticate() {
  try {
    const tokenAccess: string = yield call(accessToken.get);
    if (tokenAccess) {
      const valid: boolean = yield call(verifyToken, tokenAccess);
      if (!valid) {
        const newToken: { token: string } = yield call(refreshToken);
        if (newToken?.token) {
          yield call(accessToken.set, removeQuotes(newToken.token));
          yield put(authActions.isAuthenticatedTrue());
        } else {
          yield put(authActions.logout());

          return new Error('Your session is expired.');
        }
      } else {
        yield put(authActions.isAuthenticatedTrue());
      }
    }
  } catch (e) {
    console.warn(e);
  } finally {
    yield put(authActions.initialize());
  }
}
