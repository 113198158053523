import { put, apply, select } from 'redux-saga/effects';
import { api } from '@REST/api';
import { feesActions } from '@bus/fees/actions';
import {
  ChangeFeeActionPayload,
  FetchFeesListActionPayload,
} from '@bus/fees/typedefs';
import { FormTypes } from '@core/Form/typedefs';
import { feesSelector } from '@bus/fees/selectors';

export function* changeFee({
  payload: { id, commission },
  meta: { resolve, reject },
}: FormTypes.PayloadActionWithPromiseMeta<ChangeFeeActionPayload>) {
  try {
    yield put(feesActions.startFeeChanging(id));

    const response: Response = yield apply(api, api.patch, [
      {
        endpoint: `listings/${id}/co-op-fee`,
        body: JSON.stringify({ commission }),
      },
    ]);

    if (!response.ok) {
      reject();

      return new Error();
    }

    const { filter } = yield select(feesSelector);
    yield put(feesActions.fetchFees({ filter }));
    resolve();
  } catch (e) {
    console.warn(e);
  } finally {
    yield put(feesActions.fillProcessingFee(null));
    yield put(feesActions.stopFeeChanging());
  }
}
