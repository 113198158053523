import { takeEvery, all, call } from 'redux-saga/effects';
import { authActions } from '@bus/auth/actions';

// eslint-disable-next-line prettier/prettier
import { sendEmail, authenticate, logIn, logout } from './workers';

// IMPORTS

function* watchAuthenticate() {
  yield takeEvery(authActions.authenticate.type, authenticate);
}

function* watchSendEmail() {
  yield takeEvery(authActions.sendEmail.type, sendEmail);
}

function* watchLogIn() {
  yield takeEvery(authActions.logIn.type, logIn);
}

function* watchLogout() {
  yield takeEvery(authActions.logout.type, logout);
}

// WATCHERS
export function* watchAuth() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    call(watchAuthenticate),
    call(watchSendEmail),
    call(watchLogIn),
    call(watchLogout),
  ]);
}
