import { put, call, apply } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '@REST/api';
import { feesActions } from '@bus/fees/actions';
import {
  FetchFeesListActionPayload,
  ISearchResponse,
} from '@bus/fees/typedefs';
import { defaultFilter, defaultList } from '@bus/fees/slice';

export function* fetchFees({
  payload: { filter },
}: PayloadAction<FetchFeesListActionPayload>) {
  try {
    yield put(feesActions.startFeesFetching());

    const { limit, offset, search, commissionStatus } = filter;

    const params: any = {
      limit: limit.toString(),
      offset: offset.toString(),
    };
    if (search?.length) params.search = search;

    let query;
    const status = commissionStatus === 'EMPTY' ? null : commissionStatus;

    if (commissionStatus === 'ALL') {
      query = new URLSearchParams(params);
    } else {
      query = new URLSearchParams({
        ...params,
        where: JSON.stringify({
          commissionStatus: status,
        }),
      });
    }

    const response: Response = yield apply(api, api.get, [
      { endpoint: `users/me/agent/listings?${query}` },
    ]);

    if (!response.ok) {
      yield put(
        feesActions.fillFeesList({
          ...defaultList,
          filter: { ...defaultFilter, commissionStatus },
          error: true,
        }),
      );

      return new Error();
    }
    const data: ISearchResponse = yield call([response, 'json']);
    yield put(feesActions.fillFeesList({ ...data, filter, error: false }));
  } catch (e) {
    console.warn(e);
  } finally {
    yield put(feesActions.stopFeesFetching());
  }
}
