import prepareActions from '@helpers/prepareActions';
import { createAction } from '@reduxjs/toolkit';

import feesSlice from './slice';

export const feesActions = {
  ...feesSlice.actions,
  fetchFees: createAction('lists/fetchFees', (payload) => {
    return { payload };
  }),
  changeFee: createAction('lists/changeFee', prepareActions.movePromiseToMeta),
  // INJECT
};
