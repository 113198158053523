import { apply, call, put } from 'redux-saga/effects';

import { api } from '@REST/api';
import { FormTypes } from '@core/Form/typedefs';

import { LogInActionPayload } from '../../typedefs';

export function* logIn({
  payload,
  meta: { resolve, reject },
}: FormTypes.PayloadActionWithPromiseMeta<LogInActionPayload>) {
  try {
    const response: Response = yield apply(api, api.get, [
      {
        endpoint: `auth/login/agent?token=${payload.token}`,
        unsafe: true,
      },
    ]);

    if (!response.ok) {
      reject();

      return new Error();
    }

    const { token } = yield call([response, 'json']);

    window.localStorage.setItem('accessToken', JSON.stringify(token));
    resolve();
  } catch (e) {
    console.warn(e);
  }
}
