/* eslint-disable prettier/prettier */
import { lazy } from 'react';

import layouts from '@layouts/index';
import { book } from '@routes/book';
import { AppRoute } from '@setup/typedefs';

const LogInGooglePage = lazy(() => import('@pages/common/LogInGooglePage'));
const SubscriptionStatusPage = lazy(
  () => import('@pages/common/SubscriptionStatusPage'),
);

// IMPORTS
export const commonRoutes: AppRoute<any>[] = [
  {
    path: `${book.logInGoogle}`,
    Component: LogInGooglePage,
    Layout: layouts.AuthLayout,
    layoutProps: {},
  },
  {
    path: `${book.subscription}`,
    Component: SubscriptionStatusPage,
    Layout: layouts.CommonLayout,
    layoutProps: {},
  },
  // INJECT
];
